import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from 'gatsby'
import ProjectWork from '../components/project-work'
import '../styles/project-component.css'



const IndexPage = () => {

    const data= useStaticQuery(graphql`
    query imageQuery{
      firstline: allProjectsJson(
        filter: {line: {eq: "1"}}
      ){
        edges{
          node{
            title
            ano
            description
            slug
            imagecover{
              childImageSharp{
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      secondline:allProjectsJson(
        filter: {line: {eq: "2"}}
      ){
        edges{
          node{
            title
            ano
            description
            slug
            imagecover{
              childImageSharp{
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      thirdline: allProjectsJson(
        filter: {line: {eq: "3"}}
      ){
        edges{
          node{
            title
            ano
            description
            slug
            imagecover{
              childImageSharp{
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      
    }
  `)  



  const projects = data.firstline.edges;
  const projects2row = data.secondline.edges;
  const projects3row = data.thirdline.edges;
  

    return (
        <Layout>
             <div id="wrapper-works">
                
{projects.map(({node: project }) => {
        const title = project.title;
        const slug = project.slug;
        const imagecover = project.imagecover.childImageSharp.fluid;
        const ano = project.ano;
        const description = project.description;
      
        return(
          
          <div class="col col-50" key={`id_projectwork`}>
  
          <ProjectWork 
          title={title}
          slug={slug}
          imagecover={imagecover}
          ano={ano}
          description={description}
          />
          </div>

          
          
          
          

      );
        })}
       
        </div>

        <div id="wrapper-works">
                
                {projects2row.map(({node: project }) => {
                        const title = project.title;
                        const slug = project.slug;
                        const imagecover = project.imagecover.childImageSharp.fluid;
                        const ano = project.ano;
                        const description = project.description;
                      
                        return(
                          
                          <div class="col col-50">
                  
                          <ProjectWork 
                          title={title}
                          slug={slug}
                          imagecover={imagecover}
                          ano={ano}
                          description={description}
                          />
                          </div>
                
                          
                          
                          
                          
                
                      );
                        })}
                       
        </div>
        <div id="wrapper-works">
                
                {projects3row.map(({node: project }) => {
                        const title = project.title;
                        const slug = project.slug;
                        const imagecover = project.imagecover.childImageSharp.fluid;
                        const ano = project.ano;
                        const description = project.description;
                      
                        return(
                        
                          <div class="col col-50">
                  
                          <ProjectWork 
                          title={title}
                          slug={slug}
                          imagecover={imagecover}
                          ano={ano}
                          description={description}
                          />
                          </div>
                      
                
                          
                          
                          
                          
                
                      );
                        })}
                       
        </div>
 
        </Layout>
    )

}

export default IndexPage