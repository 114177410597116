import React from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import '../styles/project-component.css'

import '../styles/utilitary.css'

const ProjectWork = ({ slug, title, imagecover, ano, description, homepage }) => (
    
   
        
        
                    <div data-sal="slide-up" data-sal-delay="50" data-sal-duration="800" data-sal-easing="ease">
                    <div class="imagem-capa" >
                        <div class="onlyimg" >
                    <Link to={`work/${slug}/`}>
                        <Image fluid={imagecover} alt={title} />
                    </Link>
                    </div>
                    
                    <p class="gt-light titulo-project">{title}</p>
                    <p class="gt-light ano-project">{ano}</p>
                    </div>
                    </div>

              
          
        
    
    
  
)
export default ProjectWork